import React, { useState, useEffect } from 'react';
import styles from './Taginput.module.css';
import Loadersmall from "../loader/Loadersmall";
import add from "../../assets/icons/svg/scan.png";
import useFileParser from '../../hooks/useFileParser';
import useResumeParser from '../../hooks/useResumeParser';

const FileParse = ({ field, uploadHandler, uploading, formData, setFormData, onInputChange ,setUpdating }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [parseClicked, setParseClicked] = useState(false);
    const [fileData, setFileData] = useState(null);
    const [extractedText, setExtractedText] = useState(null);
    const { parseFile, isLoading:parseLoading, error:parseError} = useFileParser();
    const { data, loading, error } = useResumeParser(parseClicked ? extractedText : null);

    const handleFileUpload = async (e) => {
        console.log('handle upload working')
        setFileData(e.target.files[0])
        uploadHandler(field?.name, e);
    };

    console.log(parseError);

    const parseDoc = async () => {
        if (fileData) {
            try {
                setIsLoading(true);
                setParseClicked(true);
                setUpdating(true);
                const parsedText = await parseFile(fileData);
                console.log('Parsed Text:', parsedText);
                setExtractedText(parsedText);

            } catch (error) {
                console.error('Error parsing document:', error);
            } 
        }
    };

    const copyData = (name, data) => {
        onInputChange(name, data);
      };

    useEffect(() => {
        console.log(data);
        if (data) {
            copyData('full_name', data?.full_name);
            copyData('mobile_number', data?.mobile_number);
            copyData('email', data?.email);
            copyData('current_location', data?.current_location);
            copyData('prefered_location', [...data?.prefered_location]);
            copyData('skillset', [...data?.skillset]);
            copyData('employment_details', [...data?.employment_details]);
            copyData('notice_period', data?.notice_period);
            copyData('total_exp', data?.total_exp);
            data?.gender && copyData('gender', data?.gender && data.gender.charAt(0).toUpperCase() + data.gender.slice(1).toLowerCase());
            copyData('expected_ctc', data?.expected_ctc);
            setIsLoading(false);
            setUpdating(false);
            setParseClicked(false);
        }
      }, [data]);

   

    return (
        <div className={styles.fileUploadContainer}>
            <label className={`${styles.field_label_1}`}>
                <div>
                    {field.label}
                </div>

                

                <div className={`${styles.field_add}`} onClick={parseDoc}>
                    {(isLoading ) ? 
                        <Loadersmall /> : 
                        <img src={add} className={`${styles.icon}`} title='Parse Resume' />
                    }
                </div>
            </label>

            <input
                className={`${styles.date}`}
                type="file"
                placeholder={field.placeholder}
                onChange={handleFileUpload}
                accept=".pdf,.docx,image/*"
            />
            {uploading && <Loadersmall />}
        </div>
    );
};

export default FileParse;