import React from "react";
import styles from "../modals.module.css";
import arrowright1 from "../../../assets/icons/svg/arrowright1.svg";
import { useState, useEffect } from "react";
import DynamicFormGenerator from "../../dynamicformgenerator/Dynamicformgenerator";
import { ToastContainer, toast } from "react-toastify";
import { axiosPrivateCall, apiCall } from "../../../constants";
import Loadersmall from "../../loader/Loadersmall";

function Create(props) {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
  const formFields = [
    {
      name: "full_name",
      label: "Candidate Name",
      type: "text",
      placeholder: "Enter full name",
      isRequired: true,
      title: "Enter the candidate's complete name as it appears on their resume"
    },
    {
      name: "email",
      label: "Email",
      type: "text",
      placeholder: "Enter email",
      isRequired: true,
      title: "Enter the candidate's valid email address for communication"
    },
    {
      name: "mobile_number",
      label: "Mobile Number",
      type: "text",
      placeholder: "Enter mobile number",
      isRequired: true,
      title: "Enter the candidate's contact number with country code if applicable"
    },
    {
      name: "gender",
      label: "Gender",
      type: "select",
      placeholder: "Enter gender",
      isRequired: true,
      title: "Select the candidate's gender from the dropdown"
    },
    {
      name: "current_location",
      label: "Current Location",
      type: "text",
      placeholder: "Enter current location",
      isRequired: true,
      title: "Enter where the candidate is currently located (city/state)"
    },
    {
      name: "prefered_location",
      label: "Preferred Location",
      type: "locationinput",
      placeholder: "Enter preferred location",
      isRequired: true,
      title: "Add locations where the candidate is willing to work - type each location and press space or enter"
    },
    {
      name: "expected_ctc",
      label: "Expected CTC",
      type: "text",
      placeholder: "Enter expected CTC",
      isRequired: true,
      title: "Enter the salary package expected by the candidate (annual)"
    },
    {
      name: "current_ctc",
      label: "Current CTC",
      type: "text",
      placeholder: "Enter current CTC",
      isRequired: true,
      title: "Enter the candidate's current salary package (annual)"
    },
    {
      name: "notice_period",
      label: "Notice Period",
      type: "text",
      placeholder: "Enter notice period",
      isRequired: true,
      title: "Enter how many days/months notice the candidate needs to give to their current employer"
    },
    {
      name: "skillset",
      label: "Skillset",
      type: "taginput",
      placeholder: "Enter skillset",
      isRequired: true,
      title: "Add the candidate's skills - type each skill and press space or enter"
    },
    {
      name: "resume_url",
      label: "Upload Resume",
      type: "file",
      placeholder: "Enter skillset",
      isRequired: true,
      title: "Upload the candidate's resume/CV in PDF, DOC, or DOCX format - you can parse it automatically after upload"
    },
    {
      name: "employment_details",
      label: "Employment Details",
      type: "employmentinput",
      placeholder: "Enter employment details",
      isRequired: true,
      title: "Add details about the candidate's work history including company names and roles"
    },
    {
      name: "total_exp",
      label: "Total Experience",
      type: "text",
      placeholder: "Enter total experience",
      isRequired: true,
      title: "Enter the candidate's total years of professional experience"
    },
    // Add more form fields as needed
  ];
  const [formState, setFormState] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Api Call to get form-template to Construst the form.
    // axiosPrivateCall(
    //     `/api/v1/demand/getDemandForm`
    // ).then((res) => {
    //     console.log(res.data);
    //     setFormFields(res.data);
    // }).catch((e) => {
    //     console.log(e);
    // });
  }, [formState]);

  const handleInputChange = (fieldName, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };

  function validateField(fieldName, value) {
    let errorMessage = "";

    if (
      fieldName === "current_ctc" ||
      fieldName === "expected_ctc" ||
      fieldName === "notice_period"
    ) {
      errorMessage = isNaN(parseFloat(value.trim()))
        ? "Invalid input. Kindly enter a valid numeric value."
        : "";
    }

    if (fieldName === "skillset") {
      errorMessage = value.length ? "" : "Required";
    }

    if (fieldName === "prefered_location") {
      errorMessage = value.length ? "" : "Required";
    }

    if (fieldName === "mobile_number") {
      // Remove all non-digit characters (spaces, dashes, plus signs, parentheses)
      const digitsOnly = value.replace(/\D/g, '');
      
      // Handle common country code cases
      let nationalNumber = digitsOnly;
      
      // If it starts with country code like 91 for India
      if (digitsOnly.startsWith('91') && digitsOnly.length > 10) {
        nationalNumber = digitsOnly.substring(2);
      }
      
      // Check if resulting number has valid length (allowing 10-12 digits for different countries)
      if (nationalNumber.length < 10 || nationalNumber.length > 12) {
        errorMessage = "Invalid mobile number format";
      }
    }

    if (fieldName === "email") {
      if (!String(value.trim()).toLowerCase().match(emailRegex)) {
        errorMessage = "Invalid Email";
      }
    }

    return errorMessage;
  }

  const validateForm = (formTemplate, formState, setErrors) => {
    let newErrors = {};

    formTemplate.forEach((field) => {
      if (
        field.isRequired &&
        (formState[field?.name] === "" || formState[field?.name] === undefined)
      ) {
        newErrors[field.name] = "Required";
      } else {
        newErrors[field.name] = validateField(
          field.name,
          formState[field?.name]
        );
      }
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      ...newErrors,
    }));

    return newErrors;
  };

  function isFormValid() {
    // Use await to wait for the promise returned by validateForm
    const updatedErrors = validateForm(formFields, formState, setErrors);

    // Check if there are no errors in the updated errors object
    return Object.values(updatedErrors).every((error) => error === "");
  }

  const handleSubmit = () => {
    if (isFormValid()) {
      setIsLoading(true);

      let payload = { ...formState };

      apiCall(
        "post",
        `/api/v1/candidate/createCandidate`,
        payload,
        {},
        axiosPrivateCall
      ).then((res) => {
        if (res) {
          toast.success("Candidate Created Successfully!");
          props.onClose();
          props.setsudoUpdate((prev) => !prev);
        }
      });
    } else {
      // Form is not valid, handle accordingly (display errors, etc.)
      console.log("Form validation failed.", formState, errors);
    }
  };

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.title_container}`}>
        <div className={`${styles.line_1}`}>ADD A</div>

        <div className={`${styles.line_2_wrapper}`}>
          <div className={`${styles.line_2}`}>CANDIDATE</div>
        </div>
      </div>

      <div className={`${styles.form_container}`}>
        <DynamicFormGenerator
          formFields={formFields}
          onInputChange={handleInputChange}
          formData={formState}
          setFormData={setFormState}
          errors={errors}
          setErrors={setErrors}
          handleSubmit={handleSubmit}
        />
      </div>

      <div className={`${styles.submit_container}`}>
        <div className={`${styles.button_1}`} onClick={handleSubmit}>
          {!isLoading && <div>SUBMIT</div>}
          {!isLoading && <img src={arrowright1}></img>}
          {isLoading && <Loadersmall />}
        </div>

        <div className={`${styles.button_2}`} onClick={props.onClose}>
          CANCEL
        </div>
      </div>
    </div>
  );
}

export default Create;
