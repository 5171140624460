import React, { useState, useEffect, useRef } from "react";
import { axiosPrivateCall, apiCall } from "../../constants";
import styles from "./Taginput.module.css";
import useDebounce from '../../hooks/useDebounce';

const Searchinput = ({ name, onInputChange, formData, title }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const selectRef = useRef(null);

  const { debounce } = useDebounce();

  useEffect(() => {
    if (name === "candidate") {
      if (formData[name]) {
        apiCall(
          "get",
          `/api/v1/candidate/getCandidateDetails?candidate_id=${formData[name]?._id}`,
          null,
          {},
          axiosPrivateCall
        ).then((res) => {
          if (res) {
            const options = {
              value: res?._id,
              label: `${res?.full_name} `,
            };
            setSearchTerm(options.label);
          }
        });
      }
    } else if (name === "demand") {
      if (formData[name]) {
        apiCall(
          "get",
          `/api/v1/demand/getDemandDetails?demand_id=${formData[name]?._id}`,
          null,
          {},
          axiosPrivateCall
        ).then((res) => {
          if (res) {
            const options ={
              value: res?._id,
              label: `${res?.job_role}`,
            };
            setSearchTerm(options.label);
          }
        });
      }
    } else {
      setSearchTerm(formData[name]);
    }
  }, []);

  const handleToggle = () => {
    setIsOpen(true);
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
    setSearchTerm(option.label);
    onInputChange(name, option.value);
    setIsOpen(false);
  };

  const handleClickOutside = (e) => {
    if (selectRef.current && !selectRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  function Search(term) {
    // Call your search API here with the debounced search term

    apiCall(
      "get",
      (name === "demand")
        ? `/api/v1/demand/searchDemandElastic?searchTerm=${term}&searchFields=job_role,stake_holder.company_name`
        : `/api/v1/candidate/searchCandidatesElastic?searchTerm=${term}&searchFields=candidate_id,first_name,last_name,email`,
      null,
      {},
      axiosPrivateCall
    ).then((res) => {
      if (res) {
        let buffer = res?.hits?.map((hit) => hit?._source);
        if (buffer?.length) {
          const options = buffer?.map((dataObj, i) => ({
            value: res?.hits[i]?._id,
            label:
              name === "demand"
                ? `${dataObj.demand_id} | ${dataObj.job_role}`
                : `${dataObj.candidate_id} | ${dataObj.full_name}  `,
          }));
          setSuggestions(options);
        }
      }
    });

  }

  // Effect to trigger the debounced search when the searchTerm changes

 

  const handleInputChange = debounce((newValue) => {
    // Perform some time-consuming operation with the debounced value
    Search(newValue);
  }, 500);
  

  

  // Handle input change
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    handleInputChange(event.target.value);
  };

  return (
    <div className={styles.container} ref={selectRef}>
      <div className="select-input" onClick={handleToggle}>
        <input
          type="text"
          value={searchTerm}
          className={styles.input_field_1}
          onChange={handleChange}
          placeholder="Search for an option..."
          title={title || "Search and select an option"}
        />
      </div>
      {isOpen && (
        <div className={styles.suggestions_dropdown}>
          {suggestions?.map((option) => (
            <div
              key={option.value}
              className={styles.suggestion_item}
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Searchinput;
