import React, { useState, useEffect } from 'react';
import styles from './Taginput.module.css'; // Adjust the import path based on your project structure
import add from "../../assets/icons/svg/scan.png"
import Loadersmall from '../loader/Loadersmall';
import useJdParser from '../../hooks/useJdParser';

const Jdinput = ({ formData, setFormData, name, label, setUpdating, title }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [parseClicked, setParseClicked] = useState(false);
  const { data, loading, error } = useJdParser(parseClicked ? formData.job_description : null);

  const handleInput = (event) => {
    setFormData(prevState => ({
      ...prevState,
      [name]: event.target.value,
    }));
  };

  const copyData = (name, data) => {
    if (data) {
      setFormData(prevState => ({
        ...prevState,
        [name]: data,
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: "",
      }));
    }
  };

  const parseJd = () => {
    if (formData.job_description) {
      setIsLoading(true);
      setUpdating(true);
      setParseClicked(true);
    }
  };

  // useEffect to handle data from useJdParser
  useEffect(() => {
    if (data) {
      copyData('custom_entries', data);
      copyData('job_role', data?.role_name);
      copyData('location', [...data?.locations]);
      copyData('skillset', [...data?.required_skills, ...data?.preferred_skills]);
      copyData('minimum_experience', data?.minimum_experience);
      copyData('maximum_experience', data?.maximum_experience);
      copyData('notice_period', data?.notice_period);
      copyData('current_ctc', data?.max_ctc);
      setIsLoading(false);
      setUpdating(false);
    }
  }, [data]);

  return (
    <>
      <label className={`${styles.field_label_1}`}>
        <div>
          {label}
        </div>

        <div className={`${styles.field_add}`} onClick={parseJd} >
          {isLoading ? <Loadersmall /> : <img src={add} className={`${styles.icon}`} title='Parse JD' />}
        </div>
      </label>

      <textarea
        placeholder="Enter Job Description"
        className={styles.textarea}
        title={title || "Enter job description and click the parse icon to extract information"}
        onChange={handleInput}
      />
    </>
  );
};

export default Jdinput;
