import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import './CvConstructor.css';
import { apiCall, axiosPublicCall } from '../../constants';

/**
 * CvConstructor Component
 * 
 * This component renders a CV (Curriculum Vitae) based on:
 * 1. A template structure fetched from the API
 * 2. Candidate data fetched from the API
 * 
 * The template uses variables like {{first_name}} which are replaced
 * with actual candidate data.
 */
const CvConstructor = () => {
  // ================ STATE MANAGEMENT ================
  const [template, setTemplate] = useState(null);
  const [candidateData, setCandidateData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Reference to the CV element for PDF generation
  const cvRef = useRef(null);
  
  // Used to access URL parameters
  const location = useLocation();

  // ================ DATA FETCHING ================
  useEffect(() => {
    fetchCvData();
  }, [location]);

  /**
   * Fetches both the template and candidate data from APIs
   */
  const fetchCvData = async () => {
    try {
      // 1. Get IDs from URL parameters
      const queryParams = new URLSearchParams(location.search);
      const stakeholderId = queryParams.get('stakeholderId');
      const candidateId = queryParams.get('candidateId');

      // Validate required parameters
      if (!stakeholderId || !candidateId) {
        throw new Error('Missing stakeholderId or candidateId in URL');
      }

      // 2. Fetch data from APIs
      const templateData = await fetchTemplateData(stakeholderId);
      const candidateInfo = await fetchCandidateData(candidateId);
      
      // 3. Update state with fetched data
      setTemplate(templateData);
      setCandidateData(candidateInfo);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to load CV data. Please try again later.');
      setLoading(false);
    }
  };

  /**
   * Fetches template data for a specific stakeholder
   */
  const fetchTemplateData = async (stakeholderId) => {
    const response = await apiCall(
      'get',
      `api/v1/cv_template/getStakeHolderTemplate?stake_holder=${stakeholderId}`,
      null,
      {},
      axiosPublicCall
    );
    
    if (!response) {
      console.error('Template response is empty');
    }
    
    return response;
  };

  /**
   * Fetches candidate details by ID
   */
  const fetchCandidateData = async (candidateId) => {
    const response = await apiCall(
      'get',
      `api/v1/candidate/getCandidateDetails?candidate_id=${candidateId}`,
      null,
      {},
      axiosPublicCall
    );
    
    if (!response) {
      console.error('Candidate response is empty');
    }
    
    return response;
  };

  // ================ TEMPLATE PROCESSING ================
  /**
   * Replaces template variables with actual candidate data
   * Example: {{first_name}} -> "John"
   */
  const processTemplateText = (text) => {
    if (!text || !candidateData) return text;

    // 1. Handle simple text replacements
    let processedText = text
      .replace(/{{first_name}}/g, candidateData.full_name?.split(' ')[0] || '')
      .replace(/{{last_name}}/g, candidateData.full_name?.split(' ').slice(1).join(' ') || '')
      .replace(/{{full_name}}/g, candidateData.full_name || '')
      .replace(/{{email}}/g, candidateData.email || '')
      .replace(/{{mobile_number}}/g, candidateData.mobile_number || '')
      .replace(/{{gender}}/g, candidateData.gender || '')
      .replace(/{{current_location}}/g, candidateData.current_location || '')
      .replace(/{{prefered_location}}/g, candidateData.prefered_location?.join(', ') || '')
      .replace(/{{expected_ctc}}/g, candidateData.expected_ctc || '')
      .replace(/{{current_ctc}}/g, candidateData.current_ctc || '')
      .replace(/{{notice_period}}/g, candidateData.notice_period || '')
      .replace(/{{total_exp}}/g, candidateData.total_exp || '')
      .replace(/{{resume_url}}/g, candidateData.resume_url || '#');

    // 2. Handle skills list
    if (text.includes('{{skillset}}') && candidateData.skillset) {
      const skillsText = Array.isArray(candidateData.skillset) 
        ? candidateData.skillset.join(', ') 
        : candidateData.skillset;
      
      processedText = processedText.replace(/{{skillset}}/g, skillsText);
    }

    // 3. Handle employment details (nested data)
    if (text.includes('{{employment_details') && candidateData.employment_details) {
      const regex = /{{employment_details\[(\d+)\]\.([^}]+)}}/g;
      
      processedText = processedText.replace(regex, (match, index, property) => {
        const idx = parseInt(index);
        if (candidateData.employment_details[idx]) {
          return candidateData.employment_details[idx][property] || '';
        }
        return '';
      });
    }

    return processedText;
  };

  // ================ TEMPLATE RENDERING ================
  /**
   * Main function to render template blocks
   */
  const renderTemplateBlocks = () => {
    if (!template) {
      return null;
    }
    
    // 1. Find the blocks array in the template structure
    const blocks = findTemplateBlocks(template);
    
    if (!blocks || !Array.isArray(blocks) || blocks.length === 0) {
      return <div className="cv-error">Template has no content blocks</div>;
    }

    // 2. Filter out any resume-related blocks
    const filteredBlocks = filterResumeBlocks(blocks);
    
    // 3. Render the filtered blocks
    return (
      <div className="cv-document-content">
        {filteredBlocks.map((block, index) => renderBlock(block, index))}
      </div>
    );
  };

  /**
   * Finds the blocks array in different template structures
   */
  const findTemplateBlocks = (templateData) => {
    // Check nested template structure first
    if (templateData.template?.blocks && Array.isArray(templateData.template.blocks)) {
      return templateData.template.blocks;
    }
    
    // Check direct blocks property
    if (templateData.blocks && Array.isArray(templateData.blocks)) {
      return templateData.blocks;
    }
    
    return null;
  };

  /**
   * Filters out resume-related blocks from the template
   */
  const filterResumeBlocks = (blocks) => {
    const filteredBlocks = [];
    let skipNext = false;
    
    for (let i = 0; i < blocks.length; i++) {
      const block = blocks[i];
      
      // Skip this block if flagged by previous iteration
      if (skipNext) {
        skipNext = false;
        continue;
      }
      
      // Check if this is a Resume header
      if (block.type === 'header' && 
          block.data.text.toLowerCase().includes('resume')) {
        
        // If the next block is a paragraph with resume link, skip both
        if (i < blocks.length - 1 && 
            blocks[i+1].type === 'paragraph' && 
            blocks[i+1].data.text.toLowerCase().includes('resume')) {
          skipNext = true;
        }
        
        // Skip this header
        continue;
      }
      
      filteredBlocks.push(block);
    }
    
    return filteredBlocks;
  };

  /**
   * Renders a single template block based on its type
   */
  const renderBlock = (block, index) => {
    switch (block.type) {
      // Header block (h1, h2, h3, etc.)
      case 'header':
        return renderHeaderBlock(block, index);
        
      // Paragraph block
      case 'paragraph':
        return renderParagraphBlock(block, index);
        
      // List block
      case 'list':
        return renderListBlock(block, index);
        
      // Unknown block type
      default:
        console.warn('Unknown block type:', block.type);
        return null;
    }
  };

  /**
   * Renders a header block with the appropriate heading level
   */
  const renderHeaderBlock = (block, index) => {
    const HeaderTag = `h${block.data.level || 2}`;
    return (
      <HeaderTag 
        key={index} 
        className={`cv-header cv-header-${block.data.level || 2}`}
      >
        {processTemplateText(block.data.text)}
      </HeaderTag>
    );
  };

  /**
   * Renders a paragraph block
   */
  const renderParagraphBlock = (block, index) => {
    return (
      <p 
        key={index} 
        className="cv-paragraph" 
        dangerouslySetInnerHTML={{ __html: processTemplateText(block.data.text) }}
      />
    );
  };

  /**
   * Renders a list block with special handling for label-value pairs
   */
  const renderListBlock = (block, index) => {
    return (
      <div key={index} className="cv-list">
        {block.data.items.map((item, itemIndex) => {
          const processedItem = processTemplateText(item);
          
          // Handle skills list (items with commas)
          if (item.includes('{{skillset}}') && processedItem.includes(',')) {
            return renderSkillsList(processedItem, itemIndex);
          }
          
          // Handle label-value pairs (format: "Label: Value")
          if (processedItem.includes(':')) {
            return renderLabelValuePair(processedItem, itemIndex);
          }
          
          // Default rendering for other list items
          return (
            <div key={itemIndex} className="cv-list-item">
              {processedItem}
            </div>
          );
        }).flat()}
      </div>
    );
  };

  /**
   * Renders a list of skills as individual spans
   */
  const renderSkillsList = (skillsText, itemIndex) => {
    return skillsText.split(',').map((skill, skillIndex) => (
      <span 
        key={`${itemIndex}-skill-${skillIndex}`} 
        className="cv-skill-item"
      >
        {`${skill.trim()}, `}
      </span>
    ));
  };

  /**
   * Renders a label-value pair with structured divs
   */
  const renderLabelValuePair = (text, itemIndex) => {
    const [label, ...valueParts] = text.split(':');
    const value = valueParts.join(':').trim(); // Rejoin in case value contains colons
    
    return (
      <div key={itemIndex} className="cv-list-item-pair">
        <div className="cv-list-item-container">
          <div className="cv-list-item-label">{label.trim()}:</div>
          <div className="cv-list-item-value">{value}</div>
        </div>
      </div>
    );
  };

  // ================ PDF GENERATION ================
  /**
   * Generates and downloads a PDF of the CV
   */
  const handleDownloadPDF = async () => {
    if (!cvRef.current) return;

    try {
      // 1. Convert CV DOM element to canvas
      const canvas = await html2canvas(cvRef.current, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');
      
      // 2. Create PDF document
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      
      // 3. Calculate image positioning
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 5;

      // 4. Add image to PDF
      pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
      
      // 5. Save the PDF file (footer is already in the HTML content)
      const fileName = `CV_${candidateData?.full_name || 'candidate'}.pdf`;
      pdf.save(fileName);
    } catch (err) {
      console.error('Error generating PDF:', err);
      setError('Failed to generate PDF. Please try again.');
    }
  };

  // ================ COMPONENT RENDERING ================
  // Show loading indicator while fetching data
  if (loading) {
    return <div className="cv-loading">Loading CV data...</div>;
  }

  // Show error message if something went wrong
  if (error) {
    return <div className="cv-error">{error}</div>;
  }

  // Main component render
  return (
    <div className="cv-container">
      {/* PDF Download Button */}
      <div className="cv-actions">
        <button className="cv-download-btn" onClick={handleDownloadPDF}>
          Download CV as PDF
        </button>
      </div>

      {/* CV Document */}
      <div className="cv-document" ref={cvRef}>
        <div className="cv-content">
          {renderTemplateBlocks()}
        </div>
        <div className="cv-footer">
          Powered by Scout Hawk
        </div>
      </div>
    </div>
  );
};

export default CvConstructor;
