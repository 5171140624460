import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';
import 'pdfjs-dist/build/pdf.worker.mjs';

// Set the worker source
GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${GlobalWorkerOptions.version}/pdf.worker.min.js`;

// import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';

// // Import the worker loader
// import * as pdfjs from 'pdfjs-dist/build/pdf';
// import pdfWorker from 'pdfjs-dist/build/pdf.worker.mjs';

// Set the correct worker source
// GlobalWorkerOptions.workerSrc = pdfWorker;






// Set up PDF.js worker


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


