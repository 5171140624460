import React from "react";
import styles from "./Header.module.css";
import status from "../../assets/icons/svg/status.svg";
import question from "../../assets/icons/svg/question.svg";
import fire from "../../assets/icons/svg/fire.svg";
import notification from "../../assets/icons/svg/notification.svg";
import menu from "../../assets/icons/svg/menu.svg";

function Header(props) {

    function getTitle(path) {
        let title = '';
      
        switch (path) {
            case '/':
                title = 'Control Panel';
            break;

            case '/demand':
                title = 'Demand Listing';
            break;

            case '/candidate':
                title = 'Candidate Library';
            break;

            case '/submission':
                title = 'Submission Listing';
            break;

            case '/reports':
                title = 'Report';
            break;

            case '/employee':
                title = 'Employee Console';
            break;

            case '/stakeholder':
                title = 'Stake Holders';
            break;

            case '/settings':
                title = 'Settings';
            break;

            case '/agent':
                title = 'Agent Console';
            break;

            case '/analytics':
                title = 'Analytics';
            break;

            case '/demand/demand_specifics':
                title = 'Demand';
            break;
          
      
            default:
                title = 'Default Title';
            break;
        }
      
        return title;
      }
      
    

    
    
    return (
        <div className={`${styles.container}`}>
            <div className={`${styles.left_section}`}>
                

                <div className={`${styles.title_container}`}>
                    <div className={`${styles.divider}`}/>
                    <div>{getTitle(props.pathname)}</div>
                </div>

                <div className={`${styles.status_container}`}>
                    <img src={status}/>
                </div>

                <div className={`${styles.button_primary}`}>
                    <img src={question}/>
                    <div>Online Support</div>
                </div>

            </div>

            <div className={`${styles.right_section}`}>
                <div className={`${styles.user_container}`}>
                    <div className={`${styles.user_avatar}`}>

                    </div>
                    
                    <div className={`${styles.user_details}`}>
                        <div>{props?.email}</div>
                        <div className={`${styles.user_role}`}>{props?.user?.designation}</div>
                    </div>

                    <div className={`${styles.plan_wrapper}`}>
                        <div className={`${styles.plan_container}`}>
                                <img src={fire}/>
                                <div>Premium</div>
                        </div>
                    </div>
                </div>

                <div className={`${styles.status_container}`}>
                    <img src={notification}/>
                </div>

                <div className={`${styles.status_container}`}>
                    <img src={menu}/>
                </div>
                
            </div>

        </div>
    );
}

export default Header;