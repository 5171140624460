import React, { useState, useRef, useEffect } from 'react';
import styles from './Taginput.module.css'; // Adjust the import path based on your project structure
import add from "../../assets/icons/svg/add.svg"

const Employmentinput = ({ formData, setFormData, name, label, title }) => {
    const defaultEmployDetail = {
      company_name: "",
      role:"",
      start_date: "",
      end_date: "",
    };
  
    const [tagArray, setTagArray] = useState([defaultEmployDetail]);

    useEffect(() => {
        if (formData[name])
        {
          setTagArray([...formData[name]])
        }
    }, []);


    useEffect(() => {
        setFormData((prevState) => ({
          ...prevState,
          [name]: tagArray,
        }));
    }, [tagArray]);
  
      
  
    const handleInputChange = (event, index, field) => {
      setTagArray((prevArray) => {
        const newArray = [...prevArray];
        newArray[index][field] = event.target.value;
        return newArray;
      });
    };

    const handleCheckboxChange = (index) => {
        setTagArray((prevArray) => {
          const newArray = [...prevArray];
          newArray.forEach((item, i) => {
            item.is_current = i === index;
          });
          return newArray;
        });
    };
  
    const handleRemoveItem = (index) => {
      setTagArray((prevArray) => prevArray.filter((_, i) => i !== index));
    };

    const addField = () => {
        setTagArray((prevArray) => [...prevArray, { ...defaultEmployDetail }]);
    };
  
    return (
      <>
         <label className={`${styles.field_label_1}`}>
            <div>
                {label}
            </div> 

            <div className={`${styles.field_add}`} onClick= {addField}>
                <img src={add}/>
            </div>
        </label> 
        <div className={styles.container_1}>
          {tagArray.map((item, index) => (
            <div key={index} className={styles.field_container}>
              <div className={styles.top_container_1}>
                <input
                  type="text"
                  value={item.company_name}
                  onChange={(e) => handleInputChange(e, index, "company_name")}
                  placeholder="Company Name"
                  title={title ? `${title} - Company Name` : "Enter the company name"}
                  className={styles.input_field_1}
                />

                <input
                  type="text"
                  value={item.role}
                  onChange={(e) => handleInputChange(e, index, "role")}
                  placeholder="Role"
                  title={title ? `${title} - Role` : "Enter the job role or position"}
                  className={styles.input_field_1}
                />
              </div>
  
              <div className={styles.sub_container}>
                <input
                  type="text"
                  defaultValue={item.start_date}
                  placeholder={'From'}
                  title={title ? `${title} - Start Date` : "Enter the start date"}
                  onChange={(e) => handleInputChange(e, index, "start_date")}
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                  className={styles.input_field_half}
                />
                <input
                  type="text"
                  defaultValue={item.end_date}
                  onChange={(e) => handleInputChange(e, index, "end_date")}
                  placeholder={'Till'}
                  title={title ? `${title} - End Date` : "Enter the end date or leave blank if current"}
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                  className={styles.input_field_half}
                />

                <div className={styles.bottom_container}>
                    <input
                    type="checkbox"
                    title='Set Current Company'
                    onChange={() => handleCheckboxChange(index)}
                    className={styles.input_field}
                    checked={item.is_current}
                    />

                    <div className={styles.close_wrap} onClick={() => handleRemoveItem(index)}><img className={styles.close} src={add}/></div>
                </div>

                
              </div>
  
              
            </div>
          ))}
        </div>
      </>
    );
  };
  
  export default Employmentinput;