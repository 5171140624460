import React, { useState, useEffect, useCallback, useMemo, memo } from 'react';
import { motion } from 'framer-motion';
import { apiCall, axiosPrivateCall } from '../../constants';
import dataStore from '../../store/store';
import Loader from '../../components/loader/Loader';
import styles from './Analytics.module.css';
import RechartsBarGraph from './components/RechartsBarGraph';
import analyticsConfigData from './analyticsConfig.json';

// Safe deep clone of config to avoid modifying the original
const analyticsConfig = JSON.parse(JSON.stringify(analyticsConfigData));

// Convert processor strings to functions
try {
  analyticsConfig.views.forEach(view => {
    if (Array.isArray(view.facets)) {
      view.facets.forEach(facet => {
        if (facet.processorStr) {
          try {
            facet.processor = new Function('return ' + facet.processorStr)();
          } catch (error) {
            console.error(`Failed to parse processor for facet ${facet.id}:`, error);
            facet.processor = data => data; // Fallback processor
          }
        }
      });
    }
  });
} catch (error) {
  console.error("Error initializing analytics config:", error);
}

// Process chart data with error handling
export const processChartData = (facetId, data) => {
  try {
    // Find the facet with the matching ID across all views
    for (const view of analyticsConfig.views) {
      if (!Array.isArray(view.facets)) continue;
      
      const facet = view.facets.find(f => f && f.id === facetId);
      if (facet && typeof facet.processor === 'function') {
        return facet.processor(data);
      }
    }
    console.warn(`No processor found for facet: ${facetId}`);
    return data; // Return original data if no processor found
  } catch (error) {
    console.error(`Error processing chart data for facet ${facetId}:`, error);
    return null;
  }
};

export { analyticsConfig };

// Main Analytics Component
const Analytics = () => {
  const { current_user, setObjectData } = dataStore();
  const [selectedViewId, setSelectedViewId] = useState('comprehensive');
  const [selectedFacetIds, setSelectedFacetIds] = useState([]);
  const [formValues, setFormValues] = useState({
    aggregate: current_user?.id || '',
    startDate: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    endDate: new Date().toISOString().split('T')[0]
  });
  const [facetData, setFacetData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [loadingFacets, setLoadingFacets] = useState([]);
  const [error, setError] = useState(null);
  const [employeeList, setEmployeeList] = useState([]);
  const [isFetchingUsers, setIsFetchingUsers] = useState(false);

  // Get selected view configuration with error handling
  const selectedView = useMemo(() => {
    try {
      return analyticsConfig.views.find(view => view.id === selectedViewId) || analyticsConfig.views[0];
    } catch (error) {
      console.error("Error selecting view:", error);
      // Return a minimal valid view to prevent app crashing
      return { id: 'error', name: 'Error', facets: [] };
    }
  }, [selectedViewId]);

  // Determine which facets to display
  const facetsToDisplay = useMemo(() => {
    try {
      if (!Array.isArray(selectedView.facets)) {
        console.error("Selected view facets is not an array:", selectedView);
        return [];
      }
      
      return selectedFacetIds.length > 0
        ? selectedView.facets.filter(f => f && selectedFacetIds.includes(f.id))
        : selectedView.facets;
    } catch (error) {
      console.error("Error determining facets to display:", error);
      return [];
    }
  }, [selectedView, selectedFacetIds]);

  // Fetch employee list for dropdown on component mount
  useEffect(() => {
    fetchEmployees();
  }, []);

  // Fetch employees function
  const fetchEmployees = useCallback(async () => {
    setIsFetchingUsers(true);
    setError(null);
    
    try {
      const response = await apiCall(
        "get",
        "/api/v1/employee/listEmployee?skip=0&limit=150&sort_type=desc&sort_field=submission_id",
        null,
        {},
        axiosPrivateCall
      );
      
      if (response && Array.isArray(response)) {
        setEmployeeList(response);
      } else {
        console.warn("Employee data is not an array:", response);
        setEmployeeList([]);
      }
    } catch (err) {
      console.error("Error fetching employees:", err);
      setError(`Failed to fetch employees: ${err.message || 'Unknown error'}`);
      setEmployeeList([]);
    } finally {
      setIsFetchingUsers(false);
    }
  }, []);

  // Fetch data for a single facet with improved error handling
  const fetchFacetData = useCallback(async (facet) => {
    if (!facet || !facet.id || !facet.apiEndpoint) {
      console.error("Invalid facet configuration:", facet);
      return null;
    }
    
    try {
      // Mark this facet as currently loading
      setLoadingFacets(prev => [...prev.filter(f => f !== facet.id), facet.id]);
      
      // Validate params
      if (!facet.params || !facet.params.facet) {
        throw new Error(`Missing required params for facet ${facet.id}`);
      }
      
      // Validate form values
      if (!formValues.aggregate) {
        throw new Error('User selection is required');
      }
      
      if (!formValues.startDate || !formValues.endDate) {
        throw new Error('Date range is required');
      }
      
      // Validate date range
      const startDate = new Date(formValues.startDate);
      const endDate = new Date(formValues.endDate);
      
      if (startDate > endDate) {
        throw new Error('Start date cannot be after end date');
      }
      
      // Build query parameters
      const queryParams = new URLSearchParams({
        facet: facet.params.facet,
        aggregate: formValues.aggregate,
        startDate: formValues.startDate,
        endDate: formValues.endDate
      }).toString();
      
      // Make API call
      const endpoint = `${facet.apiEndpoint}?${queryParams}`;
      const data = await apiCall("get", endpoint, null, {}, axiosPrivateCall);
      
      if (data) {
        // Process data using the appropriate reducer
        const processedData = processChartData(facet.id, data);
        
        // Mark this facet as no longer loading
        setLoadingFacets(prev => prev.filter(f => f !== facet.id));
        return processedData;
      } else {
        throw new Error(`No data received for ${facet.id}`);
      }
    } catch (error) {
      // Mark this facet as no longer loading
      setLoadingFacets(prev => prev.filter(f => f !== facet.id));
      console.error(`Error fetching ${facet.id}:`, error);
      
      // Return an error object that can be handled by the UI
      return { 
        error: true, 
        message: error.message || `Failed to fetch ${facet.title || facet.id} data` 
      };
    }
  }, [formValues]);

  // Generate analytics data
  const generateAnalytics = useCallback(async () => {
    // Validate form data
    if (!formValues.aggregate) {
      setError('User selection is required');
      return;
    }
    
    // Validate date range
    try {
      const startDate = new Date(formValues.startDate);
      const endDate = new Date(formValues.endDate);
      
      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        setError('Invalid date format');
        return;
      }
      
      if (startDate > endDate) {
        setError('Start date cannot be after end date');
        return;
      }
    } catch (err) {
      setError('Invalid date format');
      return;
    }

    setIsLoading(true);
    setError(null);
    setFacetData({});
    
    // Validate facets to display
    if (!Array.isArray(facetsToDisplay) || facetsToDisplay.length === 0) {
      setError('No facets selected for display');
      setIsLoading(false);
      return;
    }
    
    setLoadingFacets(facetsToDisplay.map(f => f.id));
    
    const analyticsData = {};
    const errors = [];
    
    try {
      // Process facets sequentially to avoid overwhelming the server
      for (const facet of facetsToDisplay) {
        if (!facet || !facet.id) continue;
        
        const facetResult = await fetchFacetData(facet);
        
        if (facetResult) {
          if (facetResult.error) {
            errors.push(facetResult.message);
          } else {
            analyticsData[facet.id] = facetResult;
            setFacetData(prev => ({...prev, [facet.id]: facetResult}));
          }
        }
      }
      
      // Store analytics data in global state if we have any results
      if (Object.keys(analyticsData).length > 0) {
        setObjectData('analytics_data', analyticsData);
      }
      
      // Show errors if we have any
      if (errors.length > 0) {
        setError(`Some data could not be loaded: ${errors.join(', ')}`);
      } else if (Object.keys(analyticsData).length === 0) {
        setError('No data available for the selected criteria');
      }
    } catch (err) {
      console.error('Analytics fetch error:', err);
      setError(err.message || 'Failed to fetch analytics data');
    } finally {
      setIsLoading(false);
      setLoadingFacets([]);
    }
  }, [facetsToDisplay, formValues, fetchFacetData, setObjectData]);

  return (
    <div className={styles.analyticsContainer} role="region" aria-label="Analytics Dashboard">
      <AnalyticsControls 
        selectedView={selectedView}
        selectedViewId={selectedViewId}
        setSelectedViewId={setSelectedViewId}
        selectedFacetIds={selectedFacetIds}
        setSelectedFacetIds={setSelectedFacetIds}
        formValues={formValues}
        setFormValues={setFormValues}
        employeeList={employeeList}
        isFetchingUsers={isFetchingUsers}
        isLoading={isLoading}
        generateAnalytics={generateAnalytics}
      />

      {error && (
        <div className={styles.errorMessage} role="alert" aria-live="assertive">
          <p>{error}</p>
        </div>
      )}

      <div className={styles.dashboardContent}>
        {facetsToDisplay.length === 0 && !isLoading && (
          <div className={styles.noFacetsMessage} role="status">
            <p>No facets selected for display. Please select facets to view analytics.</p>
          </div>
        )}
        
        <div className={styles.facetsGrid}>
          {facetsToDisplay.map(facet => (
            <FacetCard 
              key={facet.id}
              facet={facet}
              facetData={facetData[facet.id]}
              isLoading={loadingFacets.includes(facet.id)}
              error={error}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

// Controls Component for view selection, facet selection, and filters
const AnalyticsControls = memo(({ 
  selectedView, 
  selectedViewId, 
  setSelectedViewId,
  selectedFacetIds,
  setSelectedFacetIds,
  formValues,
  setFormValues,
  employeeList,
  isFetchingUsers,
  isLoading,
  generateAnalytics
}) => {
  // Get unique variables across selected facets
  const getUniqueVariables = useCallback(() => {
    try {
      if (!selectedView || !Array.isArray(selectedView.facets)) {
        return [];
      }
      
      const facetsToUse = selectedFacetIds.length > 0 
        ? selectedView.facets.filter(f => f && selectedFacetIds.includes(f.id))
        : selectedView.facets;
      
      const variableMap = new Map();
      
      facetsToUse.forEach(facet => {
        if (facet && Array.isArray(facet.variables)) {
          facet.variables.forEach(variable => {
            if (variable && variable.id && !variableMap.has(variable.id)) {
              variableMap.set(variable.id, variable);
            }
          });
        }
      });
      
      return Array.from(variableMap.values());
    } catch (error) {
      console.error("Error getting unique variables:", error);
      return [];
    }
  }, [selectedView, selectedFacetIds]);

  // Handle form input changes
  const handleInputChange = useCallback((variableId, value) => {
    if (!variableId) return;
    
    setFormValues(prev => ({
      ...prev,
      [variableId]: value
    }));
  }, [setFormValues]);

  // Get variables only when dependencies change
  const uniqueVariables = useMemo(() => getUniqueVariables(), [getUniqueVariables]);
  
  // Check if the form is valid for submission
  const isFormValid = useMemo(() => {
    return formValues.aggregate && formValues.startDate && formValues.endDate;
  }, [formValues]);

  const buttonText = useMemo(() => {
    if (isLoading) return 'Loading...';
    if (!isFormValid) return 'Please fill required fields';
    return 'Generate Analytics';
  }, [isLoading, isFormValid]);

  return (
    <div className={styles.controls} role="form" aria-label="Analytics Controls">
      {/* View Selector */}
      <div className={styles.viewSelectorWrapper}>
        <h3 id="viewSelectorTitle">Select Stories</h3>
        <div className={styles.viewSelector} role="radiogroup" aria-labelledby="viewSelectorTitle">
          <div className={styles.viewOptions}>
            {analyticsConfig.views.map((view) => (
              <motion.div
                key={view.id}
                className={`${styles.viewOption} ${selectedViewId === view.id ? styles.selected : ''}`}
                onClick={() => setSelectedViewId(view.id)}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                role="radio"
                aria-checked={selectedViewId === view.id}
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    setSelectedViewId(view.id);
                  }
                }}
              >
                <div>
                  <h4>{view.name}</h4>
                  <p>{view.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* Dynamic Form Controls */}
      <div className={styles.filterControls}>
        {uniqueVariables.map(variable => (
          <div key={variable.id} className={styles.formGroup}>
            <label htmlFor={variable.id}>{variable.label || 'Input'}{variable.required && <span className="required">*</span>}</label>
            
            {variable.type === 'date' && (
              <input
                type="date"
                id={variable.id}
                value={formValues[variable.id] || ''}
                onChange={(e) => handleInputChange(variable.id, e.target.value)}
                className={styles.datePicker}
                required={variable.required}
                min={variable.id === 'endDate' ? formValues.startDate : undefined}
                max={variable.id === 'startDate' ? formValues.endDate : undefined}
                aria-label={variable.label || 'Date input'}
              />
            )}
            
            {variable.type === 'userDropdown' && (
              <div className={styles.selectContainer}>
                {isFetchingUsers ? (
                  <div className={styles.loadingSelect} aria-live="polite">Loading users...</div>
                ) : (
                  <select
                    id={variable.id}
                    value={formValues[variable.id] || ''}
                    onChange={(e) => handleInputChange(variable.id, e.target.value)}
                    className={styles.select}
                    required={variable.required}
                    aria-label={variable.label || 'User selection'}
                    aria-busy={isFetchingUsers}
                  >
                    <option value="">Select User</option>
                    {employeeList && employeeList.length > 0 ? (
                      employeeList.map(employee => (
                        <option 
                          key={employee._id || employee.id || Math.random().toString(36).slice(2, 9)} 
                          value={employee._id || employee.id}
                        >
                          {employee.user_name || employee.name || employee.email || employee._id || 'Unknown User'}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>No users available</option>
                    )}
                  </select>
                )}
              </div>
            )}
            
            {variable.type !== 'date' && variable.type !== 'userDropdown' && (
              <input
                type="text"
                id={variable.id}
                value={formValues[variable.id] || ''}
                onChange={(e) => handleInputChange(variable.id, e.target.value)}
                placeholder={`Enter ${variable.label || 'value'}`}
                className={styles.input}
                required={variable.required}
                aria-label={variable.label || 'Text input'}
              />
            )}
          </div>
        ))}
        <motion.button
        className={styles.fetchButton}
        onClick={generateAnalytics}
        whileHover={!isLoading && isFormValid ? { scale: 1.02 } : {}}
        whileTap={!isLoading && isFormValid ? { scale: 0.98 } : {}}
        disabled={!isFormValid || isLoading}
        title={'Generate Analytics'}
        aria-busy={isLoading}
        aria-disabled={!isFormValid || isLoading}
      >
        {'Generate '}
      </motion.button>
      </div>
      
      
    </div>
  );
});

// Facet Card Component for displaying individual metrics
const FacetCard = memo(({ facet, facetData, isLoading, error }) => {
  // Render the appropriate chart for the facet
  const renderChart = useCallback(() => {
    if (isLoading) {
      return (
        <div className={styles.facetLoading} aria-live="polite" role="status">
          <Loader />
        </div>
      );
    }

    if (error && !facetData) {
      return (
        <div className={styles.facetError} role="alert">
          <p>Error loading data</p>
        </div>
      );
    }

    if (!facetData || !facetData.chartData) {
      return (
        <div className={styles.noData} role="status">
          <p>No data available for the selected period</p>
        </div>
      );
    }

    // Check if the data has the right shape
    if (!facetData.chartData.labels || !facetData.chartData.datasets) {
      console.error("Invalid chart data structure:", facetData);
      return (
        <div className={styles.facetError} role="alert">
          <p>Invalid data format</p>
        </div>
      );
    }

    // Use our Recharts bar graph component
    return (
      <RechartsBarGraph 
        data={facetData}
        color={facet.color || '#4338CA'}
        title={facet.title || 'Chart'}
      />
    );
  }, [facetData, isLoading, error, facet]);

  // Safely extract summary data
  const summary = facetData?.summary || {};
  const facetTitle = facet.title || 'Chart';

  return (
    <div 
      className={styles.facetCard} 
      data-type="bar"
      data-facet={facet.id}
      role="figure"
      aria-labelledby={`facet-title-${facet.id}`}
    >
      <div className={styles.facetHeader}>
        <h3 id={`facet-title-${facet.id}`}>{facetTitle}</h3>
        {facetData?.summary && (
          <div className={styles.statRow} role="list" aria-label="Summary statistics">
            {summary.total !== undefined && (
              <div className={styles.facetStat} role="listitem">
                <span className={styles.statLabel}>Total:</span> {typeof summary.total === 'number' ? summary.total.toLocaleString() : summary.total}
              </div>
            )}
            {summary.avg !== undefined && (
              <div className={styles.facetStat} role="listitem">
                <span className={styles.statLabel}>Avg:</span> {typeof summary.avg === 'number' ? summary.avg.toLocaleString() : summary.avg}
              </div>
            )}
            {summary.rate !== undefined && (
              <div className={styles.facetStat} role="listitem">
                <span className={styles.statLabel}>Rate:</span> {typeof summary.rate === 'number' ? summary.rate.toLocaleString() : summary.rate}
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.chartContainer}>
        {renderChart()}
      </div>
    </div>
  );
});

export default Analytics;
