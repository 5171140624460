import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { flattenObject, flattenObjectForDisplay } from "../../utils/csvExport";
import styles from "./CSVExportModal.module.css"; // Create a dedicated CSS module
import Checkbox from "../checkbox/Checkbox";

/**
 * CSV Export Modal Component
 * 
 * This component allows users to:
 * 1. Select which fields to export to CSV
 * 2. Rename the column headers for the CSV export
 * 3. Reorder fields by dragging and dropping
 * 4. Search for specific fields
 */
const CSVExportModal = ({ data, onClose, onExport, title = "Export Data" }) => {
  // ===== STATE MANAGEMENT =====
  const [fieldConfigs, setFieldConfigs] = useState([]); // Stores all field configurations
  const [selectAll, setSelectAll] = useState(true);     // Controls the "Select All" checkbox
  const [searchTerm, setSearchTerm] = useState('');     // User's search input
  const [fieldLabels, setFieldLabels] = useState({});
  
  // ===== INITIALIZATION =====
  useEffect(() => {
    // Only process if we have data
    if (data && data.length > 0) {
      try {
        // Get first item as sample to extract field structure
        const sampleItem = data[0];
        
        // Convert nested object structure to flat paths
        const flattenedPaths = flattenObject(sampleItem);
        
        // Create configuration for each field
        const fields = Object.entries(flattenedPaths).map(([path, value]) => {
          // Extract the field name from the path (last segment)
          const displayKey = path.split('.').pop();
          
          // Format the display name to look nicer (snake_case to Title Case)
          const displayName = displayKey
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
            
          // Create the field configuration object
          return {
            originalKey: path,            // Full path for data access
            displayName: displayName,     // Editable display name
            initialDisplayName: displayName, // Static original name (for labels)
            include: true                 // Whether to include in export
          };
        });
        
        // Add CV Link field at the top of the list
        const finalFields = [
          {
            originalKey: 'cv_link',
            displayName: 'CV Link',
            initialDisplayName: 'CV Link',
            include: true
          },
          ...fields // Add all other fields
        ];
        
        // Update state with field configurations
        setFieldConfigs(finalFields);
      } catch (error) {
        console.error("Error processing export data:", error);
        setFieldConfigs([]);
      }
    }
  }, [data]); // Only re-run if data changes
  
  // ===== FIELD MANAGEMENT FUNCTIONS =====
  
  /**
   * Toggle inclusion of a field in export
   */
  const toggleField = (id) => {
    const index = fieldConfigs.findIndex(field => `field-${field.originalKey}` === id);
    if (index !== -1) {
      // Create a new array to maintain immutability
      const updated = [...fieldConfigs];
      // Toggle the include status
      updated[index].include = !updated[index].include;
      setFieldConfigs(updated);
      
      // Update select all status based on whether all fields are selected
      setSelectAll(updated.every(field => field.include));
    }
  };
  
  /**
   * Update the display name for a field (column header in CSV)
   */
  const updateDisplayName = (index, value) => {
    const updated = [...fieldConfigs];
    updated[index].displayName = value;
    setFieldConfigs(updated);
  };
  
  /**
   * Toggle selection of all fields
   */
  const toggleSelectAll = () => {
    const newSelectAll = !selectAll;
    // Update all fields to match the new select all status
    const updated = fieldConfigs.map(field => ({
      ...field,
      include: newSelectAll
    }));
    setFieldConfigs(updated);
    setSelectAll(newSelectAll);
  };
  
  // ===== FILTERING AND SORTING =====
  
  /**
   * Filter fields based on search term
   */
  const filteredFields = fieldConfigs.filter(field => 
    field.originalKey.toLowerCase().includes(searchTerm.toLowerCase()) ||
    field.displayName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  /**
   * Handle drag-and-drop reordering of fields
   */
  const onDragEnd = (result) => {
    // If dropped outside the list, do nothing
    if (!result.destination) return;
    
    // Find the actual indices in the full array (not just filtered view)
    const sourceIndex = fieldConfigs.findIndex(
      field => field.originalKey === filteredFields[result.source.index].originalKey
    );
    const destinationIndex = fieldConfigs.findIndex(
      field => field.originalKey === filteredFields[result.destination.index].originalKey
    );
    
    // Create a new array and reorder items
    const items = Array.from(fieldConfigs);
    const [reorderedItem] = items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, reorderedItem);
    
    setFieldConfigs(items);
  };

  // ===== RENDER COMPONENT =====
  return (
    <div className={styles.main_container}>
      {/* Modal Header */}
      <div className={styles.top_container}>
        <div className={`${styles.title_container}`}>
          <div className={`${styles.line_1}`}>EXPORT</div>
          <div className={`${styles.line_2_wrapper}`}>
            <div className={`${styles.line_2}`}>CSV</div>
          </div>
        </div>
      </div>
      
      {/* Field Selection Area */}
      <div className={styles.mid_container}>
        {/* Search and Select All Controls */}
        <div className={styles.search_filter_container}>
          <div className={styles.search_box}>
            <input
              type="text"
              placeholder="Search fields..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={styles.searchbar}
            />
          </div>
          
          <div className={styles.select_all_container}>
            <Checkbox
              checked={selectAll}
              userId="select-all"
              setData={() => toggleSelectAll()}
            />
            <label className={styles.select_label}>
              Select All Fields
            </label>
          </div>
        </div>
        
        {/* Draggable Field List */}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="fields-list">
            {(provided) => (
              <div 
                className={styles.fields_list}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {/* Show message if no fields match search */}
                {filteredFields.length === 0 ? (
                  <div className={styles.empty_message}>
                    {searchTerm ? "No fields match your search" : "No fields available to export"}
                  </div>
                ) : (
                  <>
                    {/* Map through fields and create draggable items */}
                    {filteredFields.map((field, index) => {
                      const checkboxId = `field-${field.originalKey}`;
                      return (
                        <Draggable 
                          key={field.originalKey} 
                          draggableId={field.originalKey} 
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className={`${styles.field_item} ${snapshot.isDragging ? styles.dragging : ''}`}
                            >
                              {/* Drag Handle */}
                              <div 
                                className={styles.drag_handle} 
                                {...provided.dragHandleProps}
                              >
                                ⋮⋮
                              </div>
                              
                              {/* Field Checkbox and Label */}
                              <div className={styles.field_checkbox}>
                                <Checkbox
                                  checked={field.include}
                                  userId={checkboxId}
                                  setData={() => {
                                    const updated = [{
                                      items: [{
                                        jsUserId: checkboxId,
                                        checked: !field.include
                                      }]
                                    }];
                                    toggleField(checkboxId);
                                  }}
                                />
                                {/* Label always shows initialDisplayName (remains static) */}
                                <label className={styles.field_name}>
                                  {field.initialDisplayName}
                                </label>
                              </div>
                              
                              {/* Editable Field Input (for column header) */}
                              <div className={styles.field_input}>
                                <input
                                  type="text"
                                  value={field.displayName}
                                  onChange={(e) => updateDisplayName(
                                    fieldConfigs.findIndex(f => f.originalKey === field.originalKey),
                                    e.target.value
                                  )}
                                  placeholder="Column Header"
                                  disabled={!field.include}
                                  className={field.include ? styles.enabled_input : styles.disabled_input}
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </>
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      
      {/* Modal Footer with Buttons */}
      <div className={styles.bottom_container}>
        <div className={styles.button_wrapper}>
          <button 
            onClick={() => {
              console.log('Exporting fields in order:', fieldConfigs.filter(f => f.include).map(f => f.displayName));
              onExport(data, fieldConfigs);
            }}
            className={styles.primary_btn}
            disabled={!fieldConfigs.some(field => field.include)}
          >
            Export 
          </button>
          <button 
            onClick={onClose} 
            className={styles.secondary_btn}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CSVExportModal; 