import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const baseURL = "https://api.scouthawk.in";
// const baseURL = "http://localhost:4001";


const axiosPublicCall = axios.create({
  baseURL,
});


const axiosPrivateCall = axios.create({
  baseURL,
});

const axiosAuthCall = axios.create({
  baseURL,
});

axiosAuthCall.interceptors.request.use(function (config) {
  const token = localStorage.getItem('auth_token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

axiosPrivateCall.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

const apiCall = async (method, endpoint, data = null, headers = {}, axiosInstance = axios) => {
  try {
    const response = await axiosInstance({
      method,
      url: endpoint,
      data,
      headers,
    });
    return response.data;
  } catch (error) {
    console.error('API Error:', error);

    if (error.message === 'Network Error') {
      // Network error
      toast.error('Network error. Please check your internet connection.');
    } else if (axios.isAxiosError(error) && error.response) {
      // The request was made and the server responded with a status code
      toast.error(`2 ${error.response.data}`);
    }  else if (axios.isAxiosError(error) && !error.response) {
      // The request was made but no response was received
      toast.error(`3 ${error.response.data}`);
    } else {
      // Other errors
      toast.error('An unexpected error occurred.');
    }

    // Rethrow the error to allow the caller to handle it if necessary
  }
};

export { axiosPublicCall, axiosPrivateCall, axiosAuthCall, apiCall };



// baseURL: 'https://api.scouthawk.in',
// baseURL: "http://localhost:4001",


