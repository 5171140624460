/**
 * Utility to flatten nested objects for CSV export
 * Converts nested properties into dot notation in a flat structure
 */
export function flattenObject(obj, prefix = '') {
  if (!obj || typeof obj !== 'object') return {};
  
  return Object.keys(obj).reduce((acc, key) => {
    const pre = prefix.length ? `${prefix}.` : '';
    
    if (
      obj[key] !== null && 
      typeof obj[key] === 'object' && 
      !Array.isArray(obj[key]) && 
      Object.keys(obj[key] || {}).length > 0
    ) {
      // Recursively flatten nested objects
      Object.assign(acc, flattenObject(obj[key], `${pre}${key}`));
    } else if (Array.isArray(obj[key])) {
      // Handle arrays - join with commas or stringify if they contain objects
      if (obj[key].length > 0 && typeof obj[key][0] === 'object') {
        // For arrays of objects, convert to a readable string representation
        acc[`${pre}${key}`] = obj[key].map(item => 
          typeof item === 'object' ? JSON.stringify(item) : item
        ).join(', ');
      } else {
        acc[`${pre}${key}`] = obj[key].join(', ');
      }
    } else {
      // Base case: add the property with its value
      acc[`${pre}${key}`] = obj[key];
    }
    
    return acc;
  }, {});
}

/**
 * Improved function that flattens objects but keeps only the last part of the key
 * and formats it nicely (removing underscores, capitalizing words)
 */
export function flattenObjectForDisplay(obj) {
  // First flatten the object normally
  const flatObj = flattenObject(obj);
  
  // Create a new object with simplified keys
  return Object.keys(flatObj).reduce((result, fullKey) => {
    // Extract the last part of the key (after the last dot)
    const lastKeyPart = fullKey.split('.').pop();
    
    // Format the key: replace underscores with spaces and capitalize words
    const formattedKey = lastKeyPart
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    
    // Handle potential duplicate keys by adding a suffix if needed
    if (result[formattedKey] !== undefined) {
      // Find parent context to differentiate
      const keyParts = fullKey.split('.');
      const parentContext = keyParts.length > 1 ? keyParts[keyParts.length - 2] : '';
      result[`${formattedKey} (${parentContext})`] = flatObj[fullKey];
    } else {
      result[formattedKey] = flatObj[fullKey];
    }
    
    return result;
  }, {});
}

/**
 * Generate CSV content from data based on selected field configurations
 */
export function generateCustomCSV(data, fieldConfigs) {
  // Handle empty data
  if (!data || data.length === 0) {
    return '';
  }
  
  // Get headers from field configs
  const includedFields = fieldConfigs.filter(field => field.include);
  
  // Verify order preservation
  console.log('CSV Export Order:', {
    configuredOrder: includedFields.map(f => f.displayName),
    originalIndices: includedFields.map(f => fieldConfigs.indexOf(f))
  });
  
  const headers = includedFields.map(field => field.displayName);
  
  // Process each data item
  const rows = data.map(item => {
    return includedFields.map(field => {
      // Special handling for CV link
      if (field.originalKey === 'cv_link') {
        const stakeholderId = item.stake_holder || '';
        const candidateId = item.candidate_id || '';
        return formatCSVValue(`https://app.scouthawk.in/cv?stakeholderId=${stakeholderId}&candidateId=${candidateId}`);
      }
      
      // For regular fields, extract the value using dot notation path
      const path = field.originalKey.split('.');
      let value = item;
      
      // Navigate through the path
      for (const key of path) {
        value = value?.[key];
        if (value == null) {
          value = '';
          break;
        }
      }
      
      return formatCSVValue(value);
    });
  });
  
  // Combine headers and rows into CSV content
  const csvContent = [
    headers.map(header => formatCSVValue(header)).join(','),
    ...rows.map(row => row.join(','))
  ].join('\n');
  
  return csvContent;
}

/**
 * Format a value to be CSV-safe (handle quotes, commas, etc.)
 */
function formatCSVValue(value) {
  if (value === null || value === undefined) {
    return '';
  }
  
  const stringValue = String(value);
  
  // If the value contains commas, quotes, or newlines, wrap it in quotes
  if (stringValue.includes(',') || stringValue.includes('"') || stringValue.includes('\n')) {
    // Double up any quotes to escape them
    return `"${stringValue.replace(/"/g, '""')}"`;
  }
  
  return stringValue;
}

/**
 * Convert CSV content to a downloadable file
 */
export function downloadCSV(csvContent, filename = "data.csv") {
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", filename);
  link.style.visibility = 'hidden';
  
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
} 