import Sidebar from "./components/sidebar/Sidebar";
import { useState, useEffect } from "react";
import "./App.css";
import Header from "./components/header/Header";
import Demand from "./pages/demand/listing";
import Login from "./pages/userlogin/Login";
import { Routes, Route, useLocation } from "react-router-dom";
import { axiosAuthCall, axiosPrivateCall, apiCall } from "./constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Candidate from "./pages/candidate/listing";
import Submission from "./pages/submission/listing";
import Stakeholder from "./pages/stakeholder/listing";
import Employee from "./pages/employee/listing";
import Agent from "./pages/agents/agentconsole";
import Analytics from "./pages/analytics/analytics";
import logo from "./assets/icons/svg/logo.svg";
import Dashboard from "./pages/dashboard/Dashboard";
import SpecificDemand from "./pages/demand/demand";
import dataStore from "./store/store";
import CvConstructor from "./pages/cv/CvConstructor";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [sessionUser, setSessionUser] = useState({
    logged_in: false,
    user_orgs: {},
  });

  const {
    setArrayData,
    setObjectData,
  } = dataStore();

  const token = localStorage.getItem("token");
  const auth_token = localStorage.getItem("auth_token");
  const token_payload = token ? extractPayload(token) : "";
  const auth_payload = auth_token ? extractPayload(auth_token) : "";

  function extractPayload(token) {
    const parts = token.split(".");

    if (parts.length === 3) {
      const encodedPayload = parts[1];
      const decodedPayload = atob(encodedPayload);
      const payloadObject = JSON.parse(decodedPayload);
      return payloadObject;
    }
  }

  function getAppData() {
    
    apiCall(
      "get",
      "/api/v1/stakeholder/searchStakeHolder?field_name=is_deleted&field_value=false",
      null,
      {},
      axiosPrivateCall
    ).then((res) => {
      if (res) {
        setArrayData("stakeholders", res);
      }
    });

    apiCall(
      "get",
      "/api/v1/employee/searchEmployee?field_name=is_deleted&field_value=false",
      null,
      {},
      axiosPrivateCall
    ).then((res) => {
      if (res) {
        setArrayData("employee", res);
      }
    });

    apiCall("get", "/api/v1/org/getOrgInfo", null, {}, axiosPrivateCall).then(
      (res) => {
        if (res) {
          setObjectData("org_data", res);
        }
      }
    );

    getAggregateData();
  }

  function getAggregateData() {

    apiCall("get", "/api/v1/aggregate/getDashboardAggregateData", null, {}, axiosPrivateCall).then(
      (res) => {
        if (res) {
          setObjectData("aggregate_data", res);
          console.log(res)
        }
      }
    );
  }


  useEffect(() => {
    const beforeUnloadHandler = (e) => {
        if (window.location.pathname === '/agent') {
            const confirmationMessage = 'Are you sure you want to leave? Your changes may not be saved.';
            e.returnValue = confirmationMessage; // For modern browsers
            return confirmationMessage; // For legacy browsers
        }
    };

    window.addEventListener('beforeunload', beforeUnloadHandler);

    return () => {
        window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
}, []);




  useEffect(() => {
    const currentTimestamp = Math.floor(Date.now() / 1000);

    

    if (token) {
      if (token_payload?.exp > currentTimestamp) {
        getAppData();
        setLoggedIn(true);
      }
    }

    if (auth_token) {
      if (auth_payload?.exp > currentTimestamp) {
        apiCall("get", "/api/v1/user/getRoles", null, {}, axiosAuthCall).then(
          (res) => {
            if (res) {
              setSessionUser({ logged_in: true, user_orgs: res });
              setObjectData("current_user", res);
            }
          }
        );

        setIsNewUser(false);
      }
    }

    console.log("call from useEffect");

  }, []);

  const location = useLocation();
  let pathname = location.pathname;

  return (
    <div className="App">
      {pathname === "/cv" ? (
        <div className="page_container">
          <CvConstructor />
        </div>
      ) : (
        <div className="App_layout">
          {loggedIn && (
            <div className="left_container">
              <div className="logo_container">
                <img src={logo} />
              </div>
              <Sidebar />
            </div>
          )}

          <div className="right_container">
            <div className="layout_container">
              <div className="dimension_container">
                {sessionUser.logged_in && (
                  <Header
                    pathname={pathname}
                    user={sessionUser?.user_orgs}
                    email={auth_payload?.email}
                  />
                )}
                {!loggedIn && (
                  <div className="login_container">
                    <Routes>
                      <Route
                        exact
                        path="/"
                        element={
                          <Login
                            loggedIn={loggedIn}
                            setLoggedIn={setLoggedIn}
                            isNewUser={isNewUser}
                            setIsNewUser={setIsNewUser}
                            sessionUser={sessionUser}
                            setSessionUser={setSessionUser}
                            getAppData={getAppData}
                          />
                        }
                      />
                    </Routes>
                  </div>
                )}
                {loggedIn && (
                  <>
                  <div className="page_container">
                    <Routes>
                      <Route exact path="/" element={<Demand />} />
                      <Route exact path="/demand" element={<Demand />} />
                      <Route exact path="/candidate" element={<Candidate />} />
                      <Route exact path="/submission" element={<Submission />} />
                      <Route exact path="/employee" element={<Employee />} />
                      <Route exact path="/agent" element={<Agent />} />
                      <Route exact path="/analytics" element={<Analytics />} />
                      <Route exact path="/demand/demand_specifics" element={<SpecificDemand/>} />
                      <Route exact path="/stakeholder" element={<Stakeholder />} />
                    </Routes>
                  </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}

export default App;



