import React from "react";
import styles from "./Sidebutton.module.css";
import { useNavigate, useLocation } from "react-router-dom";



function Sidebutton(props) {

    const navigate = useNavigate();

    const location = useLocation();
    let pathname = location.pathname;

    const handleClick = () => {
        
            navigate(props?.to);
        
    };

    return (
        <>
            <div title={`${props.title}`} className={`${styles.icon_wrapper} ${props.to == pathname ? styles.active : ""}`} onClick={handleClick}>
                <img src={props.icon} className={`${styles.icon} `}/>
            </div>
        </>
    );
}

export default Sidebutton;