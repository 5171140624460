import React from "react";
import styles from "../modals.module.css"
import arrowright1 from "../../../assets/icons/svg/arrowright1.svg"
import { useState, useEffect } from "react";
import DynamicFormGenerator from "../../dynamicformgenerator/Dynamicformgenerator";
import { axiosPrivateCall, apiCall } from "../../../constants";
import { ToastContainer, toast } from 'react-toastify';
import Loadersmall from '../../loader/Loadersmall';


function Edit(props) {

  const formFields = [
    { name: 'demand', label: 'Demands', type: 'searchable', placeholder: 'Enter Demand ID', isRequired: true },
    { name: 'candidate', label: 'Candidates', type: 'searchinput', placeholder: 'Enter Candidate ID', isRequired: true },
    { name: 'current_progress', label: 'Current Status', type: 'select', placeholder: 'Enter skillset', isRequired: true },
    { name: 'remarks', label: 'Remarks', type: 'textarea', placeholder: 'Add remarks', isRequired: false }

    // { name: 'remarks', label: 'Remarks', type: 'textarea', placeholder: 'Enter remarks', isRequired: false },
  ];
      const [formState, setFormState] = useState(props.data);
      const [errors, setErrors] = useState({});
      const [isLoading, setIsLoading] = useState(false);

      function sanitize (datas, fields) {
        let buffer = fields.map((field) => field.name);
        let res = {};
        buffer.forEach((name) => res[name] = datas[name])
        return res;
      }


    
      const handleInputChange = (fieldName, value) => {
        setFormState((prevState) => ({
          ...prevState,
          [fieldName]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: '',
        }));
      };

    
      function validateField(fieldName, value) {
        let errorMessage = '';
    
        if (fieldName === 'current_ctc' || fieldName === 'minimum_experience' || fieldName === 'maximum_experience' || fieldName === 'no_of_positions') {
            // Check if value is a string before calling trim
            errorMessage = (typeof value === 'string' && isNaN(parseFloat(value.trim()))) ? "Invalid input. Kindly enter a valid numeric value." : '';
        }
    
        if (fieldName === 'skillset') {
            errorMessage = (value.length) ? '' : 'Required';
        }
    
        return errorMessage;
      }

      const validateForm = (formTemplate, formState, setErrors) => {
       
        let newErrors = {};
    
        formTemplate.forEach((field) => {
          if (field.isRequired && (formState[field?.name] === '' || formState[field?.name] === undefined)) {
            newErrors[field.name] = 'Required';
          } else {
            newErrors[field.name] = validateField(field.name, formState[field?.name]);
          }
        });
    
        setErrors((prevErrors) => ({
          ...prevErrors,
          ...newErrors,
        }));

        return newErrors;
      
      };
      
      function isFormValid () {
        // Use await to wait for the promise returned by validateForm
        const updatedErrors = validateForm(formFields, formState, setErrors);
      
        // Check if there are no errors in the updated errors object
        return Object.values(updatedErrors).every(error => error === '');
      };
      
      const handleSubmit = () => {
        if (isFormValid()) {
          setIsLoading(true)
          let payload = sanitize (formState, formFields);
          payload._id = formState._id;

          const curr_prog = payload.current_progress
          const prog_arr = formState.progress
          const curr_obj = prog_arr.find(i => i.state === curr_prog)
          curr_obj.time_stamp = new Date().toString()
          console.log(payload)
          payload.progress = prog_arr


          apiCall(
            "post",
            `/api/v1/submission/updateSubmission`,
            payload,
            {},
            axiosPrivateCall
          ).then((res) => {
            if (res) {
              toast.success("Submission Updated Successfully!");
              props.onClose({show: false, data:''});
              props.setsudoUpdate((prev) => !prev);
            }
          });
        } else {
          // Form is not valid, handle accordingly (display errors, etc.)
          console.log('Form validation failed.', formState, errors);
        }
      };

      function handleClose() {
        let payload = {};
          payload._id = formState._id;
          payload.current_progress = "CLOSED";

          apiCall(
            "post",
            `/api/v1/submission/updateSubmission`,
            payload,
            {},
            axiosPrivateCall
          ).then((res) => {
            if (res) {
              toast.success("Submission Closed Successfully!");
              props.onClose({show: false, data:''});
              props.setsudoUpdate((prev) => !prev);
            }
          });
      }



    return (
        <div className={`${styles.container}`}>
          <div className={`${styles.title_container}`}>
              <div className={`${styles.line_1}`}>
                  EDIT 
              </div>

              <div className={`${styles.line_2_wrapper}`}>
                  <div className={`${styles.line_2}`}>
                      SUBMISSION
                  </div>
              </div>
          </div>

          <div className={`${styles.form_container}`}>
              <DynamicFormGenerator formFields={formFields} 
                onInputChange={handleInputChange} 
                formData={formState} 
                setFormData={setFormState}
                errors={errors} 
                param={formState?.progress}
              />
              <div className={`${styles.button_3}`} onClick={handleClose}>
                <div className={`${styles.button_1}`}>{isLoading ? <Loadersmall/> : <div title='Mark as completed' className={`${styles.button_txt}`}>Close</div>}</div>
              </div>
          </div>


          <div className={`${styles.submit_container}`}>
              <div className={`${styles.button_1}`} onClick={handleSubmit}>
                  {!isLoading && <div>SUBMIT</div>}
                  {!isLoading && <img src={arrowright1}></img>}
                  {isLoading && <Loadersmall/>}
              </div>

              <div className={`${styles.button_2}`} onClick={() => {props.onClose({show: false, data:''})}}>
                  CANCEL
              </div>
              
          </div>
            
           
            
        </div>
    );
}

export default Edit;