import React, { memo } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Cell
} from 'recharts';
import styles from '../Analytics.module.css';

// Optimized tooltip component for better UX
const CustomTooltip = memo(({ active, payload, label }) => {
  if (!active || !payload || !payload.length) {
    return null;
  }
  
  return (
    <div className={styles.customTooltip} role="tooltip" aria-live="polite">
      <p className={styles.tooltipLabel}>{label || 'N/A'}</p>
      <p className={styles.tooltipValue}>
        <span>{payload[0]?.value || 0}</span>
      </p>
    </div>
  );
});

const RechartsBarGraph = memo(({ data, color = '#4338CA', title }) => {
  // Comprehensive validation
  if (!data) {
    return <div className={styles.noData} role="alert">No data available</div>;
  }
  
  if (!data.chartData || 
      !Array.isArray(data.chartData.labels) || 
      !data.chartData.datasets || 
      !Array.isArray(data.chartData.datasets) || 
      !data.chartData.datasets[0] || 
      !Array.isArray(data.chartData.datasets[0].data)) {
    console.error('Invalid chart data format', data);
    return <div className={styles.noData} role="alert">Invalid data format</div>;
  }

  // Transform the data to the format Recharts expects
  const chartData = data.chartData.labels.map((label, index) => {
    const dataValue = data.chartData.datasets[0].data[index];
    // Ensure the value is a number
    const value = Number.isFinite(parseFloat(dataValue)) ? parseFloat(dataValue) : 0;
    return {
      name: label || 'N/A',
      value,
    };
  }).filter(item => item.name); // Filter out empty labels
  
  // Safety check for empty data
  if (chartData.length === 0) {
    return <div className={styles.noData} role="alert">No data available</div>;
  }

  // Calculate max value to set domain for better visualization
  const maxValue = Math.max(...chartData.map(item => item.value || 0));
  
  // Round up to the next integer and add padding
  const yMax = maxValue <= 0 ? 10 : Math.ceil(maxValue + (maxValue * 0.1)); 
  const yDomain = [0, yMax];
  
  // Format tick values as integers only
  const formatYAxisTick = (tickValue) => {
    return Number.isFinite(tickValue) ? Math.round(tickValue) : 0;
  };

  // Create a unique gradient ID to avoid conflicts
  const gradientId = `colorGradient-${(color || '').replace('#', '').substring(0, 6)}`;

  return (
    <div className={styles.rechartsContainer} role="img" aria-label={`${title || 'Data'} bar chart`}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 30,
          }}
          barCategoryGap={10}
          barGap={0}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#f0f0f0" />
          <XAxis 
            dataKey="name" 
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#64748b', fontSize: 12 }}
            dy={10}
            tickFormatter={(value) => value && value.length > 15 ? `${value.substring(0, 15)}...` : value}
          />
          <YAxis 
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#64748b', fontSize: 12 }}
            domain={yDomain}
            tickFormatter={formatYAxisTick}
            allowDecimals={false}
          />
          <Tooltip 
            content={<CustomTooltip />}
            cursor={{ fill: 'rgba(0, 0, 0, 0.05)' }}
            wrapperStyle={{ outline: 'none' }}
          />
          <defs>
            <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={color || '#4338CA'} stopOpacity={1} />
              <stop offset="100%" stopColor={color || '#4338CA'} stopOpacity={0.6} />
            </linearGradient>
          </defs>
          <Bar 
            dataKey="value" 
            radius={[4, 4, 0, 0]} 
            fill={`url(#${gradientId})`}
            minPointSize={2}
            barSize={35}
            animationDuration={1500}
            animationEasing="ease-in-out"
          >
            {chartData.map((entry, index) => (
              <Cell 
                key={`cell-${index}`} 
                fill={`url(#${gradientId})`}
                filter="drop-shadow(0px 2px 2px rgba(0,0,0,0.1))"
              />
            ))}
            <LabelList 
              dataKey="value" 
              position="top" 
              fill="#1e293b" 
              fontSize={12}
              fontWeight="600"
              offset={10}
              formatter={(value) => Number.isFinite(value) ? Math.round(value) : 0}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
});

export default RechartsBarGraph; 