import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createPortal } from "react-dom";

// Styles and Assets
import styles from "./listing.module.css";
import placeholder from "../../assets/icons/svg/placeholder.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/svg/search.svg";
import add from "../../assets/icons/svg/add.svg";
import download from "../../assets/icons/svg/download.svg";
import more from "../../assets/icons/svg/more.svg";
import filter from "../../assets/icons/svg/filter.svg";

// Components
import Filterbutton from "../../components/filterbutton/Filterbutton";
import DemandCard from "../../components/listingcard/Demandcard";
import SubmissionCard from "../../components/listingcard/Submissioncard";
import CandidateCard from "../../components/listingcard/Candidatecard";
import Layoutmodal from "../../components/layoutmodal/Layoutmodal";
import Searchbar from "../../components/searchbar/Searchbar";
import Loader from "../../components/loader/Loader";

// Modal Components
import DemandEdit from "../../components/modals/demand/Edit";
import DemandView from "../../components/modals/demand/View";
import SubmissionCreate from "../../components/modals/submission/Create";
import SubmissionEdit from "../../components/modals/submission/Edit";
import SubmissionView from "../../components/modals/submission/View";
import CandidateEdit from "../../components/modals/candidate/Edit";
import CandidateView from "../../components/modals/candidate/View";
import CSVExportModal from "../../components/modals/CSVExportModal";

// API and Utilities
import { axiosPrivateCall, apiCall } from "../../constants";
import dataStore from "../../store/store";
import { generateCustomCSV, downloadCSV } from "../../utils/csvExport";

/**
 * DemandDetail Component
 * 
 * This component displays detailed information about a specific demand,
 * including related submissions and candidates.
 */
const DemandDetail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id"); // Get demand ID from URL

  // ======= STATE MANAGEMENT =======
  
  // 1. Main Data States
  const [demandData, setDemandData] = useState(null);           // The demand details
  const [submissionList, setSubmissionList] = useState([]);     // List of submissions for this demand
  const [candidateList, setCandidateList] = useState([]);       // List of candidates from submissions
  
  // 2. UI States
  const [loading, setLoading] = useState(true);                 // Main loading state for demand
  const [loader1, setisLoader1] = useState(false);              // Loading state for submissions
  const [error, setError] = useState(null);                     // Error state
  const [isFilterOn, setIsFilterOn] = useState(true);           // Toggle between submission/candidate view
  const [sudoUpdate, setsudoUpdate] = useState('');             // Trigger for data refresh
  
  // 3. Modal States - Each modal has {show, data} structure
  const [showAddSubmissionModal, setShowAddSubmissionModal] = useState(false);
  const [demandEditModal, setDemandEditModal] = useState({ show: false, data: {} });
  const [demandViewModal, setDemandViewModal] = useState({ show: false, data: {} });
  const [submissionEditModal, setSubmissionEditModal] = useState({ show: false, data: {} });
  const [submissionViewModal, setSubmissionViewModal] = useState({ show: false, data: {} });
  const [candidateEditModal, setCandidateEditModal] = useState({ show: false, data: {} });
  const [candidateViewModal, setCandidateViewModal] = useState({ show: false, data: {} });
  
  // 4. Export States
  const [showCSVExportModal, setShowCSVExportModal] = useState(false);
  const [exportData, setExportData] = useState([]);

  // ======= EFFECT HOOKS =======

  // Main effect - Load demand and submissions when ID changes or update happens
  useEffect(() => {
    // Reset states when ID changes
    setLoading(true);
    setError(null);
    setDemandData(null);

    // Validate ID before making API calls
    if (!id) {
      setError("Missing demand ID in URL query parameters");
      setLoading(false);
      return;
    }

    // Ensure ID is a valid MongoDB ObjectId format (24 hex characters)
    const isValidObjectId = /^[0-9a-fA-F]{24}$/.test(id);
    if (!isValidObjectId) {
      setError("Invalid demand ID format");
      setLoading(false);
      return;
    }

    // Load data
    getDemand();
    getSubmissions();
  }, [id, sudoUpdate]); // Re-fetch when ID changes or updates happen

  // Process submission data to extract candidate data
  useEffect(() => {
    if (submissionList && submissionList.length > 0) {
      // Extract candidate data from submissions
      const candidates = submissionList
        .map(submission => {
          // If submission has candidate data, use it
          if (submission.candidate) {
            return {
              ...submission.candidate,
              submission_id: submission._id, // keep reference to submission
              demand_id: id // store the demand ID
            };
          }
          return null;
        })
        .filter(candidate => candidate !== null);
      
      setCandidateList(candidates);
    } else {
      setCandidateList([]);
    }
  }, [submissionList, id]);

  // ======= DATA FETCHING FUNCTIONS =======

  /**
   * Fetch demand details from the API
   */
  async function getDemand() {
    try {
      // Double check ID is defined before making the API call
      if (!id) {
        throw new Error("Demand ID is undefined");
      }

      const response = await apiCall(
        "get",
        `/api/v1/demand/getDemandDetails?demand_id=${id}`,
        null,
        {},
        axiosPrivateCall
      );

      if (response) {
        setDemandData(response);
      } else {
        setError("No data found for this demand");
      }
    } catch (err) {
      setError(err.message || "Failed to fetch demand details");
      console.error("Error fetching demand:", err);
    } finally {
      setLoading(false);
    }
  }

  /**
   * Fetch submissions related to this demand
   */
  async function getSubmissions() {
    setisLoader1(true);
    try {
      const response = await apiCall(
        "get",
        `/api/v1/submission/getSubmissionByDemand?demand_id=${id}`,
        null,
        {},
        axiosPrivateCall
      );
      
      if (response) {
        setSubmissionList([...response]);
      }
    } catch (err) {
      console.error("Error fetching submissions:", err);
    } finally {
      setisLoader1(false);
    }
  }

  // ======= UI INTERACTION FUNCTIONS =======

  /**
   * Toggle between submission view and candidate view
   */
  function toggleViewMode() {
    setIsFilterOn(!isFilterOn);
    // No need to fetch different data, we're just toggling the display mode
  }

  /**
   * Handle preparing data for CSV export
   */
  async function handleDownload() {
    try {
      // Check if we already have the data loaded
      let rawData;
      
      if (submissionList.length > 0) {
        // Use existing data if available
        rawData = submissionList; 
      } else {
        // Fetch fresh data if needed
        const response = await apiCall(
          "get",
          `/api/v1/submission/getSubmissionByDemand?demand_id=${id}`,
          null,
          {},
          axiosPrivateCall
        );
        
        if (response) {
          rawData = response;
        }
      }
      
      // Transform the data to include only required fields
      const dataToExport = rawData.map(submission => {
        // Extract candidate data with only required fields
        const candidateData = submission.candidate ? {
          candidate_id: submission.candidate._id || '',
          full_name: submission.candidate.full_name || '',
          email: submission.candidate.email || '',
          mobile_number: submission.candidate.mobile_number || '',
          gender: submission.candidate.gender || '',
          current_location: submission.candidate.current_location || '',
          prefered_location: submission.candidate.prefered_location || '',
          expected_ctc: submission.candidate.expected_ctc || '',
          current_ctc: submission.candidate.current_ctc || '',
          notice_period: submission.candidate.notice_period || '',
          skillset: submission.candidate.skillset || '',
          total_exp: submission.candidate.total_exp || ''
        } : {};

        // Get stake_holder from demand
        const stake_holder = submission.demand?.stake_holder || '';

        // Combine the data
        return {
          ...candidateData,
          stake_holder
        };
      });
      
      // Show export modal if we have data
      if (dataToExport && dataToExport.length > 0) {
        setExportData(dataToExport);
        setShowCSVExportModal(true);
      } else {
        alert("No data available to export");
      }
    } catch (err) {
      console.error("Error preparing data for export:", err);
      alert("Failed to prepare data for export. Please try again.");
    }
  }

  /**
   * Handle export after field configuration in the export modal
   */
  const handleConfiguredExport = (data, fieldConfigs) => {
    try {
      // Generate CSV content based on configured fields
      const csvContent = generateCustomCSV(data, fieldConfigs);
      
      if (!csvContent) {
        alert("No data to export");
        return;
      }
      
      // Create filename based on current view
      const filename = isFilterOn ? 
        `candidates_for_demand_${id}_${new Date().toISOString().split('T')[0]}.csv` : 
        `submissions_for_demand_${id}_${new Date().toISOString().split('T')[0]}.csv`;
      
      // Download the CSV file
      downloadCSV(csvContent, filename);
      
      // Close the export modal
      setShowCSVExportModal(false);
    } catch (error) {
      console.error("Error during export:", error);
      alert("An error occurred while generating the export. Please try again.");
    }
  };

  /**
   * Handler for when a candidate action triggers a submission update
   */
  const handleCandidateUpdate = () => {
    // Refresh the submissions to reflect any changes made to candidates
    setsudoUpdate(prev => !prev);
  };

  /**
   * Handle demand deletion with navigation back to demand list
   */
  const handleDemandDelete = async (id) => {
    try {
      let payload = { _id: id };
      
      const response = await apiCall(
        "post",
        `/api/v1/demand/deleteDemand`,
        payload,
        {},
        axiosPrivateCall
      );
      
      if (response) {
        // Navigate back to the demands listing page
        navigate("/demand");
      }
    } catch (error) {
      console.error("Error deleting demand:", error);
    }
  };

  // ======= RENDER FUNCTIONS & STATES =======

  // Show loading state
  if (loading) {
    return (
      <div className="loading-container">
        <Loader />
      </div>
    );
  }

  // Show error state
  if (error) {
    return (
      <div className="error-container">
        <p>Error: {error}</p>
        <button onClick={() => (id ? getDemand() : navigate("/demands"))}>
          {id ? "Try Again" : "Go to Demands List"}
        </button>
      </div>
    );
  }

  // Show not found state
  if (!demandData) {
    return (
      <div className="not-found-container">
        <p>No demand found with ID: {id}</p>
        <button onClick={() => navigate("/demands")}>Go to Demands List</button>
      </div>
    );
  }

  // Main render with demand data
  return (
    <>
      {/* ======= MODALS ======= */}
      
      {/* Demand Modals */}
      {demandEditModal.show &&
        createPortal(
          <Layoutmodal>
            <DemandEdit 
              onClose={setDemandEditModal} 
              data={demandEditModal.data} 
              setsudoUpdate={setsudoUpdate} 
            />
          </Layoutmodal>,
          document.getElementById("modal-container")
        )}

      {demandViewModal.show &&
        createPortal(
          <Layoutmodal>
            <DemandView 
              onClose={setDemandViewModal} 
              data={demandViewModal.data} 
            />
          </Layoutmodal>,
          document.getElementById("modal-container")
        )}

      {/* Submission Modals */}
      {showAddSubmissionModal &&
        createPortal(
          <Layoutmodal>
            <SubmissionCreate 
              onClose={() => setShowAddSubmissionModal(false)} 
              setsudoUpdate={setsudoUpdate}
              prefillDemand={demandData} // Pass demand data for pre-filling
            />
          </Layoutmodal>,
          document.getElementById("modal-container")
        )}

      {submissionEditModal.show &&
        createPortal(
          <Layoutmodal>
            <SubmissionEdit 
              onClose={setSubmissionEditModal} 
              data={submissionEditModal.data} 
              setsudoUpdate={setsudoUpdate}
            />
          </Layoutmodal>,
          document.getElementById("modal-container")
        )}

      {submissionViewModal.show &&
        createPortal(
          <Layoutmodal>
            <SubmissionView 
              onClose={setSubmissionViewModal} 
              data={submissionViewModal.data} 
            />
          </Layoutmodal>,
          document.getElementById("modal-container")
        )}

      {/* Candidate Modals */}
      {candidateEditModal.show &&
        createPortal(
          <Layoutmodal>
            <CandidateEdit 
              onClose={setCandidateEditModal} 
              data={candidateEditModal.data} 
              setsudoUpdate={handleCandidateUpdate} 
            />
          </Layoutmodal>,
          document.getElementById("modal-container")
        )}

      {candidateViewModal.show &&
        createPortal(
          <Layoutmodal>
            <CandidateView 
              onClose={setCandidateViewModal} 
              data={candidateViewModal.data} 
            />
          </Layoutmodal>,
          document.getElementById("modal-container")
        )}

      {/* CSV Export Modal */}
      {showCSVExportModal &&
        createPortal(
          <Layoutmodal>
            <CSVExportModal 
              data={exportData}
              title={isFilterOn ? "Export Candidates" : "Export Submissions"}
              onClose={() => setShowCSVExportModal(false)}
              onExport={handleConfiguredExport}
            />
          </Layoutmodal>,
          document.getElementById("modal-container")
        )}

      {/* ======= MAIN CONTENT ======= */}
      <div className="demand-detail-container">
        {/* Demand Detail Card */}
        <DemandCard 
          key={id} 
          data={demandData} 
          setEditModal={setDemandEditModal}
          setViewModal={setDemandViewModal}
          setsudoUpdate={setsudoUpdate}
          customDeleteHandler={handleDemandDelete}
        />

        {/* Search & Filter Bar */}
        <div className={`${styles.mid_container}`} style={{ marginBottom: '20px' }}>
          {/* Search Section */}
          <div className={`${styles.search_container}`}>
            <Searchbar 
              setListing={isFilterOn ? setCandidateList : setSubmissionList}
              listing={isFilterOn ? candidateList : submissionList}
              url={isFilterOn ? 
                "candidate/searchCandidatesElastic?searchTerm=" : 
                "submission/searchSubmissionElastic?searchTerm="
              }
              fields={isFilterOn ? 
                "&searchFields=full_name,email,skillset" : 
                "&searchFields=candidate.employment_details.company_name,candidate.first_name,candidate.email"
              }
              setisLoader1={setisLoader1}
              getListing={getSubmissions}
              setIsFilterOn={setIsFilterOn}
            />

            {/* Action Buttons */}
            <div className={`${styles.buttons_container}`}>
              <div 
                title="Add Submission" 
                className={`${styles.searchbar_button}`}
                onClick={() => setShowAddSubmissionModal(true)}
              >
                <img src={add} alt="Add submission" />
              </div>
              <div 
                className={`${styles.searchbar_button}`} 
                onClick={handleDownload}
                title={isFilterOn ? "Export Candidates" : "Export Submissions"}
              >
                <img src={download} alt="Download" />
              </div>
            </div>
          </div>

          {/* Filter Section */}
          <div className={`${styles.filter_container}`}>
            <div className={`${styles.filter_wrapper}`}>
              <img src={filter} alt="Filter" />
              Filters
            </div>
            <Filterbutton 
              text="Candidate View" 
              onClick={toggleViewMode}
              isFilterOn={isFilterOn}
            />
          </div>
        </div>

        {/* Data Listing Section - Shows either Candidates or Submissions */}
        <div className={`${styles.listing_container}`}>
          {/* Loading Indicator */}
          {loader1 && <Loader />}
          
          {/* Show Candidate Cards when filter is ON */}
          {!loader1 && isFilterOn && candidateList.map((candidate) => (
            <CandidateCard
              key={candidate._id || `candidate-${candidate.submission_id}`}
              data={candidate}
              setEditModal={setCandidateEditModal}
              setViewModal={setCandidateViewModal}
              setsudoUpdate={handleCandidateUpdate}
            />
          ))}
          
          {/* Show Submission Cards when filter is OFF */}
          {!loader1 && !isFilterOn && submissionList.map((submission) => (
            <SubmissionCard
              key={submission._id}
              data={submission}
              setEditModal={setSubmissionEditModal}
              setViewModal={setSubmissionViewModal}
              setsudoUpdate={setsudoUpdate}
            />
          ))}
          
          {/* Show appropriate message when no results */}
          {!loader1 && ((isFilterOn && candidateList.length === 0) || 
                        (!isFilterOn && submissionList.length === 0)) && (
            <div className={styles.text_div}>
              {isFilterOn 
                ? "Currently no candidate data available for this demand."
                : "Currently no submissions made for this demand."
              }
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DemandDetail;
