import { useState, useEffect } from 'react';

const useResumeParser = (resumeContent) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!resumeContent) return;

        const fetchData = async () => {
            setLoading(true);
            setError(null);

            const apiKey = 'AIzaSyBYPL_pUlXXn3iaOHp_ptjjg4XHGG45c2U';
            const url = `https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash:generateContent?key=${apiKey}`;

            const payload = {
                contents: [{
                    parts: [
                        {
                            text: `You are an expert resume reader and extractor of information from resumes. \n\nRESUME CONTENT:\n'''\n${resumeContent}\n'''\n\nREAD THE ABOVE RESUME AND FILL IN THE FOLLOWING INFORMATION IN THE BELOW FORMAT:\n'''\n{
                                "full_name": "",
                                "email": "",
                                "mobile_number": "",
                                "gender": "",
                                "current_location": "",
                                "prefered_location": [String],
                                "expected_ctc": "",
                                "education": [{
                                    "degree": String,
                                    "passout_year": Number,
                                    "university": String
                                }],
                                "current_ctc": "",
                                "notice_period": "",
                                "status": "",
                                "resume_url": "",
                                "skillset": [],
                                "employment_details": [{
                                    "company_name": String,
                                    "role": String,
                                    "start_date": ISO 8601,
                                    "end_date": ISO 8601
                                }],
                                "total_exp": 0
                            }\n\nInstructions:\n1. If any information is unavailable in the resume, leave it blank.\n2. Don't change the format or add extra information.\n3. For fields like 'education' and 'employment_details,' use an array of objects with well-structured information. For employment details \n4. Ensure 'skillset' contains the top technical or functional skills based on the resume.\n5. Calculate 'total_exp' based on the employment details, using full years. \n6. For mobile numbers remove extra spacing or - in between or any wierd non mobile number characters only + character is allowed\n 7. For full_name make sure you merge names properly and leave space based on the naming convention\n8. If there are no preferred location mentioned use the city of current location as preferred location and use only city name for current location as well\n\n'''\n`
                        }
                    ]
                }],
                generationConfig: {
                    response_mime_type: 'application/json'
                }
            };

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }

                const jsonResponse = await response.json();
                const parsedResponse = JSON.parse(jsonResponse.candidates[0].content.parts[0].text);
                console.log(parsedResponse);
                setData(parsedResponse);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [resumeContent]);

    return { data, loading, error };
};

export default useResumeParser;